const COORDINATIONS = [
  {
    "name": "Regionalkoordination Leipzig",
    "Region": "Stadt Leipzig, Landkreis Leipzig, Landkreis Nordsachsen",
    "Verein": "Courage – Werkstatt für demokratische Bildungsarbeit e. V.<br/>Netzwerk für Demokratie und Courage in Sachsen",
    "Straße": "Rosa-Luxemburg-Straße 19-21",
    "Ort": "Leipzig",
    "PLZ": "04103",
    "Tel": "0341-3373498",
    "Mail": "theresa.kuehn@netzwerk-courage.de",
    "coordinates": [51.345002273429884, 12.390753527379568]
  },
  {
    "name": "Regionalkoordination Chemnitz",
    "Region": "Stadt Chemnitz, Landkreis Mittelsachsen, Erzgebirgskreis",
    "Verein": "Courage – Werkstatt für demokratische Bildungsarbeit e. V.<br/>Netzwerk für Demokratie und Courage in Sachsen",
    "Straße": "Augustusburger Straße 33",
    "Ort": "Chemnitz",
    "PLZ": "09111",
    "Tel": "0371-43319064",
    "Mail": "susann.peschel@netzwerk-courage.de",
    "coordinates": [50.832410143882704, 12.930528540509279]
  },
  {
    "name": "Regionalkoordination Dresden",
    "Region": "Landeshauptstadt Dresden, Landkreis Meißen, Landkreis Sächsische Schweiz/Osterzgebirge",
    "Verein": "Courage – Werkstatt für demokratische Bildungsarbeit e. V.<br/>Netzwerk für Demokratie und Courage in Sachsen",
    "Straße": "Könneritzstraße 7",
    "Ort": "Dresden",
    "PLZ": "01067",
    "Tel": "0351-4810069",
    "Mail": "reingard.brendler@netzwerk-courage.de",
    "coordinates": [51.057134066682586, 13.726581127372867]
  },
  {
    "name": "Regionalkoordination Ostsachsen",
    "Region": "Landkreis Görlitz und Landkreis Bautzen",
    "Verein": "RAA Sachsen e.V.",
    "Straße": "Augustastraße 35",
    "Ort": "Görlitz",
    "PLZ": "02826",
    "Tel": "03581-6845696",
    "Mail": "jessica.zuechner@raa-sachsen.de",
    "coordinates": [51.1535078386065, 14.976123056210552]
  },
  {
    "name": "Regionalkoordination Westsachsen",
    "Region": "Landkreis Zwickau und Vogtlandkreis",
    "Verein": "Alter Gasometer e. V.",
    "Straße": "Kleine Biergasse 3",
    "Ort": "Zwickau",
    "PLZ": "08056",
    "Tel": "0375-2772117",
    "Mail": "claudia.drescher@alter-gasometer.de",
    "coordinates": [50.72315408743279, 12.500226998529593]
  },
  // {
  //   "name": "Landeskoordination Sachsen",
  //   "Region": "Stadt Leipzig, Landkreis Leipzig, Landkreis Nordsachsen",
  //   "Verein": "Courage – Werkstatt für demokratische Bildungsarbeit e. V. Netzwerk für Demokratie und Courage in Sachsen",
  //   "Straße": "Könneritzstraße 7",
  //   "Ort": "Dresden",
  //   "PLZ": "01067",
  //   "Tel": "0351-32899886",
  //   "Mail": "marlene.jakob@netzwerk-courage.de",
  //   "coordinates": [51.05709027562585, 13.726480679097387]
  // },
  // {
  //   "name": "Leitung Sachsen",
  //   "Region": "Stadt Leipzig, Landkreis Leipzig, Landkreis Nordsachsen",
  //   "Verein": "Courage – Werkstatt für demokratische Bildungsarbeit e. V. Netzwerk für Demokratie und Courage in Sachsen",
  //   "Straße": "Augustusburger Straße 33",
  //   "Ort": "Chemnitz",
  //   "PLZ": "09111",
  //   "Tel": "0371-6660908",
  //   "Mail": "sor-smc-sachsen@netzwerk-courage.de",
  //   "coordinates": [50.83235932209839, 12.930592913873987]
  // },
];
const SCHOOLS = [
    {
      "Lfd. Nr.": 1,
      "Schule": "Richard-von-Schlieben-Oberschule",
      "Datum TV": "03.03.1997",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Görlitz",
      "Straße": "Schliebenstraße 19",
      "Ort": "Zittau",
      "PLZ": "02763",
      "Homepage": "https://www.sachsen.schule/~burgteichschule-zittau/index.php?page=schule",
      "Schulart": "Oberschule",
      "Patenschaft": "Die Prinzen (Musiker)",
      "coordinates": [50.889316, 14.807928]
    },
    {
      "Lfd. Nr.": 2,
      "Schule": "4. Grundschule Hoyerswerda \"Lindenschule\"",
      "Datum TV": "11.07.2000",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Bautzen",
      "Straße": "Johann-Gottfried-Herder-Straße 26",
      "Ort": "Hoyerswerda",
      "PLZ": "02997",
      "Homepage": "http://www.sn.schule.de/~gs4-hoyerswerda/",
      "Schulart": "Grundschule",
      "Patenschaft": "Horst-Dieter Brähmig (ehemaliger Oberbürgermeister der Stadt Hoyerswerda)",
      "coordinates": [51.440613, 14.267181]
    },
    {
      "Lfd. Nr.": 3,
      "Schule": "Dr.-Wilhelm-André-Gymnasium",
      "Datum TV": "02.03.2002",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Henriettenstraße 35",
      "Ort": "Chemnitz",
      "PLZ": "09112",
      "Homepage": "http://www.andregymnasium.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Siegmund Rotstein (Vors. Jüd. Gemeinde)",
      "coordinates": [50.835355, 12.902451]
    },
    {
      "Lfd. Nr.": 4,
      "Schule": "Albert-Schweitzer-Schule Aue",
      "Datum TV": "23.05.2002",
      "Region": "Chemnitz",
      "Landkreis": "Erzgebirgskreis",
      "Straße": "Robert-Koch-Straße 30",
      "Ort": "Aue",
      "PLZ": "08280",
      "Homepage": "http://www.albert-schweitzer-schule-aue.de",
      "Schulart": "Förderschule",
      "Patenschaft": "FC Erzgebirge Aue e.V. (Fußballverein)",
      "coordinates": [50.591518, 12.710279]
    },
    {
      "Lfd. Nr.": 5,
      "Schule": "Dr.-Christoph-Hufeland-Oberschule",
      "Datum TV": "23.05.2002",
      "Region": "Westsachsen",
      "Landkreis": "Vogtlandkreis",
      "Straße": "Anton-Kraus-Str. 16",
      "Ort": "Plauen",
      "PLZ": "08529",
      "Homepage": "http://www.hufeland-oberschule.de",
      "Schulart": "Oberschule",
      "Patenschaft": "VFC Plauen (Fußballverein)",
      "coordinates": [50.500611, 12.164184]
    },
    {
      "Lfd. Nr.": 6,
      "Schule": "Pestalozzischule Zwickau",
      "Datum TV": "05.11.2002",
      "Region": "Westsachsen",
      "Landkreis": "Landkreis Zwickau",
      "Straße": "Seminarstraße 3",
      "Ort": "Zwickau",
      "PLZ": "08058",
      "Homepage": "http://www.pestalozzischule-zwickau.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Jürgen Croy (Fußballspieler - Olympiasieger 1976)",
      "coordinates": [50.730676, 12.484487]
    },
    {
      "Lfd. Nr.": 7,
      "Schule": "Karl-Schmitt-Rottluff-Gymnasium",
      "Datum TV": "10.12.2002",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Hohe Straße 25/35",
      "Ort": "Chemnitz",
      "PLZ": "09112",
      "Homepage": "https://www.ksrgym.de/index.php",
      "Schulart": "Gymnasium",
      "Patenschaft": "Thomas Schönlebe (Leichtathlet)",
      "coordinates": [50.832946, 12.913571]
    },
    {
      "Lfd. Nr.": 8,
      "Schule": "Humboldtschule – Gymnasium der Stadt Leipzig",
      "Datum TV": "05.05.2003",
      "Region": "Leipzig",
      "Landkreis": "Stadt Leipzig",
      "Straße": "Möbiusstraße 8",
      "Ort": "Leipzig",
      "PLZ": "04317",
      "Homepage": "http://www.sachsen.schule/~humboldt-leipzig/schule/aktuell.html",
      "Schulart": "Gymnasium",
      "Patenschaft": "HC Leipzig (Frauenhandballverein)",
      "coordinates": [51.329963, 12.407549]
    },
    {
      "Lfd. Nr.": 9,
      "Schule": "Andert-Oberschule Ebersbach-Neugersdorf",
      "Datum TV": "06.06.2003",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Görlitz",
      "Straße": "Sachsenstraße 41",
      "Ort": "Ebersbach-Neugersdorf",
      "PLZ": "02730",
      "Homepage": "http://www.andert-mittelschule.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Lars Riedel (Sportler)",
      "coordinates": [50.993411, 14.612451]
    },
    {
      "Lfd. Nr.": 10,
      "Schule": "G.-E.-Lessing-Gymnasiums in Döbeln/Sachsen",
      "Datum TV": "01.10.2003",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachsen",
      "Straße": "Straße des Friedens 9",
      "Ort": "Döbeln",
      "PLZ": "04720",
      "Homepage": "http://www.lgd.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Matthias Brauneis (NDC Sachsen)",
      "coordinates": [51.118253, 13.124710]
    },
    {
      "Lfd. Nr.": 11,
      "Schule": "Léon-Foucault-Gymnasium",
      "Datum TV": "26.11.2003",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Bautzen",
      "Straße": "Dietrich-Bonhoeffer-Straße 20",
      "Ort": "Hoyerswerda",
      "PLZ": "02977",
      "Homepage": "http://www.foucault-gymnasium.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Markus Kavka (Moderator bei MTV) ",
      "coordinates": [51.436428, 14.254844]
    },
    {
      "Lfd. Nr.": 12,
      "Schule": "Europäisches Gymnasium Waldenburg",
      "Datum TV": "17.06.2004",
      "Region": "Westsachsen",
      "Landkreis": "Landkreis Zwickau",
      "Straße": "Altenburger Straße 44a",
      "Ort": "Waldenburg",
      "PLZ": "08396",
      "Homepage": "http://www.eurogymnasium-waldenburg.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Sebastian Krumbiegel (Musiker, \"Die Prinzen\")",
      "coordinates": [50.874966, 12.597771]
    },
    {
      "Lfd. Nr.": 13,
      "Schule": "Untere Luisenschule",
      "Datum TV": "09.07.2004",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Fritz-Matschke-Straße 21",
      "Ort": "Chemnitz",
      "PLZ": "09311",
      "Homepage": "http://www.uls.c.sn.schule.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Splash Entertainment GmbH co. KG",
      "coordinates": [50.840930, 12.902900]
    },
    {
      "Lfd. Nr.": 14,
      "Schule": "Oberschule Gablenz",
      "Datum TV": "15.03.2005",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Adelsbergstraße 90",
      "Ort": "Chemnitz",
      "PLZ": "09126",
      "Homepage": "http://www.os-gablenz.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Chemnitz 99ers (Basketballverein)",
      "coordinates": [50.830997, 12.952811]
    },
    {
      "Lfd. Nr.": 15,
      "Schule": "Pestalozzischule Radeberg Oberschule",
      "Datum TV": "29.09.2005",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Bautzen",
      "Straße": "Pestalozzistraße 1",
      "Ort": "Radeberg",
      "PLZ": "01454",
      "Homepage": "http://www.pestalozzischule-radeberg.de/index.php",
      "Schulart": "Oberschule",
      "Patenschaft": "DynamoFans Dresden e.V.",
      "coordinates": [51.113958, 13.919851]
    },
    {
      "Lfd. Nr.": 16,
      "Schule": "Oberschule am Steegenwald",
      "Datum TV": "06.10.2005",
      "Region": "Chemnitz",
      "Landkreis": "Erzgebirgskreis",
      "Straße": "Sallauminer Straße 88",
      "Ort": "Lugau",
      "PLZ": "09385",
      "Homepage": "http://www.mslugau.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Hans-Ullrich Prill (Rollstuhlleichtathlet)",
      "coordinates": [50.740005, 12.750687]
    },
    {
      "Lfd. Nr.": 17,
      "Schule": "Ernst-Rietschel-Oberschule Pulsnitz",
      "Datum TV": "22.11.2005",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Bautzen",
      "Straße": "Kühnstraße 1",
      "Ort": "Pulsnitz",
      "PLZ": "01896",
      "Homepage": "http://www.os-pulsnitz.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Thomas Neubert (Fußballspieler bei Dynamo Dresden)",
      "coordinates": [51.184684, 14.020248]
    },
    {
      "Lfd. Nr.": 18,
      "Schule": "Heinrich-Heine-Oberschule",
      "Datum TV": "18.01.2006",
      "Region": "Chemnitz",
      "Landkreis": "Erzgebirgskreis",
      "Straße": "Hermann-Uhlig-Platz 2",
      "Ort": "Lauter-Bernsbach",
      "PLZ": "08312",
      "Homepage": "http://www.heinrich-heine-oberschule.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "FC Erzgebirge Aue e.V. (Fußballverein)",
      "coordinates": [50.560182, 12.735979]
    },
    {
      "Lfd. Nr.": 19,
      "Schule": "Johann-Wolfgang-von-Goethe-Gymnasium",
      "Datum TV": "07.07.2006",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Bernsdorfer Straße 126",
      "Ort": "Chemnitz",
      "PLZ": "09126",
      "Homepage": "https://www.goethegym-chemnitz.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Berthold Brehm (Politiker, ehem. Chemnitzer Bürgermeister)",
      "coordinates": [50.815920, 12.942259]
    },
    {
      "Lfd. Nr.": 20,
      "Schule": "Oberschule \"Martin Andersen Nexö\" Zschopau",
      "Datum TV": "21.07.2006",
      "Region": "Chemnitz",
      "Landkreis": "Erzgebirgskreis",
      "Straße": "An den Anlagen 19",
      "Ort": "Zschopau",
      "PLZ": "09405",
      "Homepage": "http://www.man-zschopau.de",
      "Schulart": "Oberschule",
      "Patenschaft": " Almira Büchner (Deutsche Meister im Rollstuhl-Tanzen), Horst Wehner (MdL) und Verona Schinkitz (Jugendclub Pavillon, AG In- und Ausländer)",
      "coordinates": [50.750992, 13.073841]
    },
    {
      "Lfd. Nr.": 21,
      "Schule": "Albert-Schweitzer-Oberschule",
      "Datum TV": "20.09.2006",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Alfred-Köhler-Straße 48",
      "Ort": "Chemnitz",
      "PLZ": "09122",
      "Homepage": "http://www.albert-schweitzer-oberschule-chemnitz.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "FC Erzgebirge Aue (Fußballclub)",
      "coordinates": [50.792986, 12.889031]
    },
    {
      "Lfd. Nr.": 22,
      "Schule": "Johann-Gottfried-Herder-Gymnasium Pirna-Copitz",
      "Datum TV": "17.11.2006",
      "Region": "Dresden",
      "Landkreis": "Landkreis Sächsische Schweiz - Osterzgebirge",
      "Straße": "Rudolf-Renner-Straße 41c",
      "Ort": "Pirna",
      "PLZ": "01796",
      "Homepage": "http://www.herders.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Hugo Jensch (Historiker und Autor)",
      "coordinates": [50.973321, 13.929517]
    },
    {
      "Lfd. Nr.": 23,
      "Schule": "Stadtschule Schwarzenberg",
      "Datum TV": "20.06.2007",
      "Region": "Chemnitz",
      "Landkreis": "Erzgebirgskreis",
      "Straße": "Erlaer Straße 6",
      "Ort": "Schwarzenberg",
      "PLZ": "08340",
      "Homepage": "http://www.stadtschule-schwarzenberg.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Heidrun Hiemer (Politikerin, ehemalige Oberbürgermeisterin Schwarzenberg), FC Erzgebirge Aue (Fußballclub)",
      "coordinates": [50.536210, 12.781904]
    },
    {
      "Lfd. Nr.": 24,
      "Schule": "Georgius-Agricola-Gymnasium Chemnitz",
      "Datum TV": "21.06.2007",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Park der Opfer des Faschismus 2",
      "Ort": "Chemnitz",
      "PLZ": "09111",
      "Homepage": "http://www.agricola-gymnasium.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Berthold Brehm (Politiker, ehemaliger Bürgermeister Chemnitz)",
      "coordinates": [50.830506, 12.930550]
    },
    {
      "Lfd. Nr.": 25,
      "Schule": "Sächsische Bildungsgesellschaft für Umweltschutz und Chemieberufe Dresden mbH",
      "Datum TV": "16.11.2007",
      "Region": "Dresden",
      "Landkreis": "Stadt Dresden",
      "Straße": "Gutenbergstraße 6",
      "Ort": "Dresden",
      "PLZ": "01307",
      "Homepage": "http://www.sbg-dresden.de/start.html",
      "Schulart": "Berufsschule",
      "Patenschaft": "Hauke Hänsel (ehem. Präsident der SG Dynamo Dresden)",
      "coordinates": [51.058437, 13.769656]
    },
    {
      "Lfd. Nr.": 26,
      "Schule": "Gymnasium \"Alexander von Humboldt\" Werdau",
      "Datum TV": "29.04.2008",
      "Region": "Westsachsen",
      "Landkreis": "Landkreis Zwickau",
      "Straße": "Alexander-von-Humboldt-Straße 2-4",
      "Ort": "Werdau",
      "PLZ": "08412",
      "Homepage": "http://www.gym-werdau.de/index.php",
      "Schulart": "Gymnasium",
      "Patenschaft": "Brothers Keepers e.V.",
      "coordinates": [50.732530, 12.380509]
    },
    {
      "Lfd. Nr.": 27,
      "Schule": "Oberschule \"Clara Zetkin\"",
      "Datum TV": "30.06.2008",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachsen",
      "Straße": "Dörnerzaunstraße 2",
      "Ort": "Freiberg",
      "PLZ": "09599",
      "Homepage": "https://cms.sachsen.schule/osfbgzetkin/startseite/",
      "Schulart": "Oberschule",
      "Patenschaft": "Werner Helfen (Fahrschullehrer)",
      "coordinates": [50.912559, 13.341716]
    },
    {
      "Lfd. Nr.": 28,
      "Schule": "Oberschule Halsbrücke",
      "Datum TV": "09.07.2008",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachsen",
      "Straße": "Geschwister-Scholl Straße 8",
      "Ort": "Halsbrücke",
      "PLZ": "09633",
      "Homepage": "http://www.pausenklingel.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Sabine Ebert (Autorin)",
      "coordinates": [50.954016, 13.345140]
    },
    {
      "Lfd. Nr.": 29,
      "Schule": "Lessing-Gymnasium Hoyerswerda",
      "Datum TV": "10.07.2008",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Bautzen",
      "Straße": "Pestalozzi-Straße 1",
      "Ort": "Hoyerswerda",
      "PLZ": "02977",
      "Homepage": "https://cms.sachsen.schule/lghoy/impressum/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Michael Ankele (Aussteigerprojekt Bautzen) ",
      "coordinates": [51.437466, 14.233352]
    },
    {
      "Lfd. Nr.": 30,
      "Schule": "Geschwister-Scholl-Gymnasium Freiberg",
      "Datum TV": "26.01.2009",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachsen",
      "Straße": "Geschwister-Scholl Straße 1",
      "Ort": "Freiberg",
      "PLZ": "09599",
      "Homepage": "http://www.gsg-freiberg.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Prof. Dr. Christoph Breitkreuz (Prorektor für Außenbeziehung der Technischen Universität Bergakademie Freiberg)",
      "coordinates": [50.921051, 13.342136]
    },
    {
      "Lfd. Nr.": 31,
      "Schule": "Geschwister-Scholl-Oberschule Schönheide",
      "Datum TV": "18.03.2009",
      "Region": "Chemnitz",
      "Landkreis": "Erzgebirgskreis",
      "Straße": "Hauptstraße 70",
      "Ort": "Schönheide",
      "PLZ": "08304",
      "Homepage": "http://www.ms-schoenheide.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Kai Wilhelm (Bürgermeister Schönheide), Jörg Werner (Vorsitzender Sportverein Grün-Weiß Wernesgrün e.V.)",
      "coordinates": [50.502571, 12.531350]
    },
    {
      "Lfd. Nr.": 32,
      "Schule": "Oberschule \"Am Flughafen\"",
      "Datum TV": "08.04.2009",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Straße Usti nad Labem 277",
      "Ort": "Chemnitz",
      "PLZ": "09119",
      "Homepage": "https://www.os-amflughafen.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Herr Hüttner (Bauunternehmen)",
      "coordinates": [50.805773, 12.885381]
    },
    {
      "Lfd. Nr.": 33,
      "Schule": "Julius-Mosen-Gymnasium",
      "Datum TV": "11.06.2009",
      "Region": "Westsachsen",
      "Landkreis": "Vogtlandkreis",
      "Straße": "Melanchthonstraße 11",
      "Ort": "Oelsnitz/V.",
      "PLZ": "08606",
      "Homepage": "http://www.mosengymnasium.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Ulrike Gräßler (Sportlerin, Skisprung-Vize-Weltmeisterin)",
      "coordinates": [50.417156, 12.168622]
    },
    {
      "Lfd. Nr.": 34,
      "Schule": "Georg-Weerth-Oberschule",
      "Datum TV": "24.06.2009",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Uhlandstraße 2-4",
      "Ort": "Chemnitz",
      "PLZ": "09130",
      "Homepage": "http://georg-weerth.schule/index.php?id=8",
      "Schulart": "Oberschule",
      "Patenschaft": "Lutz Wittig (Grundstücks- und Gebäudewirtschafts-Gesellschaft m.b.H.)",
      "coordinates": [50.835727, 12.935433]
    },
    {
      "Lfd. Nr.": 35,
      "Schule": "Evangelische Oberschule Schneeberg",
      "Datum TV": "25.02.2010",
      "Region": "Chemnitz",
      "Landkreis": "Erzgebirgskreis",
      "Straße": "Keilbergring 20",
      "Ort": "Schneeberg",
      "PLZ": "08289",
      "Homepage": "https://oberschule.evangelische-schule-schneeberg.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Bernd Keller (Präsident des Fußballvereins FC Erzgebirge Aue e.V.)",
      "coordinates": [50.606838, 12.633016]
    },
    {
      "Lfd. Nr.": 36,
      "Schule": "Oberschule Strehla",
      "Datum TV": "26.05.2010",
      "Region": "Dresden",
      "Landkreis": "Landkreis Meißen",
      "Straße": "Leckwitzer Straße 2",
      "Ort": "Strehla",
      "PLZ": "01616",
      "Homepage": "http://www.sn.schule.de/~ms-strehla/",
      "Schulart": "Oberschule",
      "Patenschaft": "Harry Güldner (CDU, ehemaliger Bürgermeister Strehla)",
      "coordinates": [51.354887, 13.222889]
    },
    {
      "Lfd. Nr.": 37,
      "Schule": "August-Bebel-Oberschule Zschopau",
      "Datum TV": "09.08.2010",
      "Region": "Chemnitz",
      "Landkreis": "Erzgebirgskreis",
      "Straße": "Neckarsulmer Ring 17",
      "Ort": "Zschopau",
      "PLZ": "09405",
      "Homepage": "http://www.abs-zschopau.de/index.php",
      "Schulart": "Oberschule",
      "Patenschaft": "Klaus Baumann (CDU, ehemaliger Oberbürgermeister Zschopau)",
      "coordinates": [50.755704, 13.068010]
    },
    {
      "Lfd. Nr.": 38,
      "Schule": "Friedrich-Schiller-Gymnasium Pirna",
      "Datum TV": "26.08.2010",
      "Region": "Dresden",
      "Landkreis": "Landkreis Sächsische Schweiz - Osterzgebirge",
      "Straße": "Seminarstraße 3",
      "Ort": "Pirna",
      "PLZ": "01796",
      "Homepage": "https://www.schillergymnasium-pirna.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Café Jazz (Musiker)",
      "coordinates": [50.956750, 13.942015]
    },
    {
      "Lfd. Nr.": 39,
      "Schule": "Annenschule-Oberschule Chemnitz",
      "Datum TV": "17.09.2010",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Annenstraße 23",
      "Ort": "Chemnitz",
      "PLZ": "09111",
      "Homepage": "http://www.annenschule.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Bodo Finger (Geschäftsführer Chemnitzer Zahnradfabrik GmbH) ",
      "coordinates": [50.828056, 12.921929]
    },
    {
      "Lfd. Nr.": 40,
      "Schule": "Hans-Erlwein-Gymnasium Dresden",
      "Datum TV": "23.11.2010",
      "Region": "Dresden",
      "Landkreis": "Stadt Dresden",
      "Straße": "Eibenstocker Straße 30",
      "Ort": "Dresden",
      "PLZ": "01277",
      "Homepage": "https://cms.sachsen.schule/heg/home/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Katja Meier (Sächsische Staatsministerin der Justiz und für Demokratie, Europa und Gleichstellung)",
      "coordinates": [51.038100, 13.793921]
    },
    {
      "Lfd. Nr.": 41,
      "Schule": "Diesterwegschule Burgstädt",
      "Datum TV": "11.02.2011",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachsen",
      "Straße": "Burkersdorfer Straße 103",
      "Ort": "Burgstädt",
      "PLZ": "09217",
      "Homepage": "http://www.diesterwegschule-burgstaedt.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Lars Naumann (Bürgermeister Burgstädt), Chemnitzer FC (Fußballclub)",
      "coordinates": [50.922777, 12.804313]
    },
    {
      "Lfd. Nr.": 42,
      "Schule": "Oberschule Reichenbrand",
      "Datum TV": "05.03.2011",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Lennéstraße 1",
      "Ort": "Chemnitz",
      "PLZ": "09117",
      "Homepage": "http://www.os-reichenbrand.c.sn.schule.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Jens Carlowitz (Vertriebsleiter beim Mercedes Benz-Händler Schloz Wöllenstein)",
      "coordinates": [50.811400, 12.824881]
    },
    {
      "Lfd. Nr.": 43,
      "Schule": "Käthe-Kollwitz-Gymnasium Zwickau",
      "Datum TV": "11.04.2011",
      "Region": "Westsachsen",
      "Landkreis": "Landkreis Zwickau",
      "Straße": "Lassallestraße 1",
      "Ort": "Zwickau",
      "PLZ": "08058",
      "Homepage": "http://www.kkg-zwickau.de/index.php?article_id=1&clang=0",
      "Schulart": "Gymnasium",
      "Patenschaft": "Dr. Thomas Synofzik (Direktor Robert-Schumann-Haus Zwickau)",
      "coordinates": [50.728939, 12.485207]
    },
    {
      "Lfd. Nr.": 44,
      "Schule": "Oberschule Lohsa",
      "Datum TV": "20.07.2012",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Bautzen",
      "Straße": "Kirchstraße 4a",
      "Ort": "Lohsa",
      "PLZ": "09313",
      "Homepage": "http://www.mslohsa.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Frank Hirche (Politiker, CDU und ehemaliges Mitglied des Landtags)",
      "coordinates": [51.386280, 14.403405]
    },
    {
      "Lfd. Nr.": 45,
      "Schule": "Abendgymnasium Leipzig / Abendoberschule Leipzig",
      "Datum TV": "14.09.2012",
      "Region": "Leipzig",
      "Landkreis": "Stadt Leipzig",
      "Straße": "Czermaks Garten 8",
      "Ort": "Leipzig",
      "PLZ": "04103",
      "Homepage": "https://agyle.de/",
      "Schulart": "Oberschule | Gymnasium",
      "Patenschaft": "Roswitha Lorenz (Redakteurin und Moderatorin beim Mitteldeutschen Rundfunk (MDR))",
      "coordinates": [51.341843, 12.387089]
    },
    {
      "Lfd. Nr.": 46,
      "Schule": "Schule mit dem Förderschwerpunkt Sprache „Ernst Busch“ Chemnitz",
      "Datum TV": "22.09.2012",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Arno-Schreiter-Straße 3",
      "Ort": "Chemnitz",
      "PLZ": "09123",
      "Homepage": "www.shs-ernst-busch.de",
      "Schulart": "Förderschule",
      "Patenschaft": "Hans Voigt (Stadt-Jugendfeuerwehrwart)",
      "coordinates": [50.791626, 12.903625]
    },
    {
      "Lfd. Nr.": 47,
      "Schule": "Freies Gymnasium Penig",
      "Datum TV": "09.07.2013",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachsen",
      "Straße": "Schützenhausweg 4",
      "Ort": "Chemnitz ",
      "PLZ": "09322",
      "Homepage": "http://www.freiesgymnasiumpenig.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Thomas Schmidt (Politiker CDU, Mitglied des Landtags), FC Erzgebirge Aue e.V. (Fußballverein)",
      "coordinates": [50.931196, 12.701573]
    },
    {
      "Lfd. Nr.": 48,
      "Schule": "Diesterweg Gymnasium Plauen",
      "Datum TV": "25.01.2014",
      "Region": "Westsachsen",
      "Landkreis": "Vogtlandkreis",
      "Straße": "Diesterwegstraße 3",
      "Ort": "Plauen",
      "PLZ": "08523",
      "Homepage": "http://www.diesterweg-gymnasium.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Benjamin Zabel (SPD, Stadtrat Plauen)",
      "coordinates": [50.490853, 12.120376]
    },
    {
      "Lfd. Nr.": 49,
      "Schule": "Freie Oberschule Weißenberg",
      "Datum TV": "02.05.2014",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Bautzen",
      "Straße": "Reichenbacher Straße 2",
      "Ort": "Weißenberg",
      "PLZ": "02627",
      "Homepage": "https://stadt-weissenberg.de/kinder-in-weissenberg/freie-mittelschule-weissenberg",
      "Schulart": "Oberschule",
      "Patenschaft": "Anja Hennersdorf (Anwältin), Michael Ankele (Aussteiger_innen-Projekt)",
      "coordinates": [51.195614, 14.664575]
    },
    {
      "Lfd. Nr.": 50,
      "Schule": "Ferdinand-Sauerbruch Gymnasium",
      "Datum TV": "07.07.2014",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Bautzen",
      "Straße": "Melanchthonstraße 21",
      "Ort": "Großröhrsdorf",
      "PLZ": "01900",
      "Homepage": "http://www.ferdinand-sauerbruch-gymnasium.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Michael Harig (Landrat des sächsischen Landkreises Bautzen (CDU))",
      "coordinates": [51.143707, 14.028899]
    },
    {
      "Lfd. Nr.": 51,
      "Schule": "Förderzentrum “Clemens Winkler” Brand-Erbisdorf ",
      "Datum TV": "17.07.2014",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachsen",
      "Straße": "Am Wiesengrund 1",
      "Ort": "Brand-Erbisdorf",
      "PLZ": "09618",
      "Homepage": "www.fzbed.de",
      "Schulart": "Förderschule | Grundschule",
      "Patenschaft": "Gernot Krasselt (Politiker, CDU, ehemaliges Mitglied des Landtags), Freiberger Zeitzeugnis e.V.",
      "coordinates": [50.873500, 13.297311]
    },
    {
      "Lfd. Nr.": 52,
      "Schule": "Ruth-Pfau-Schule Berufliches Schulzentrum der Stadt Leipzig",
      "Datum TV": "05.11.2014",
      "Region": "Leipzig",
      "Landkreis": "Stadt Leipzig",
      "Straße": "Schönauer Straße 160",
      "Ort": "Leipzig",
      "PLZ": "04207",
      "Homepage": "www.ruth-pfau-schule.de",
      "Schulart": "Berufsschule",
      "Patenschaft": "Scarlett O' (Musikerin), Schwarwel (Comiczeichner)",
      "coordinates": [51.310789, 12.313051]
    },
    {
      "Lfd. Nr.": 53,
      "Schule": "Oberschule Weixdorf",
      "Datum TV": "12.01.2015",
      "Region": "Dresden",
      "Landkreis": "Stadt Dresden",
      "Straße": "Alte Dresdner Straße 22",
      "Ort": "Dresden",
      "PLZ": "01108",
      "Homepage": "https://cms.sachsen.schule/index.php?id=4721",
      "Schulart": "Oberschule",
      "Patenschaft": "Max-Planck-Institut Dresden",
      "coordinates": [51.147173, 13.801491]
    },
    {
      "Lfd. Nr.": 54,
      "Schule": "Geschwister-Scholl-Gymnasium Nossen",
      "Datum TV": "06.02.2015",
      "Region": "Dresden",
      "Landkreis": "Landkreis Meißen",
      "Straße": "Seminarweg 4",
      "Ort": "Nossen",
      "PLZ": "01683",
      "Homepage": "https://gymnasium-nossen.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Henning Homann (Politiker, SPD, Mitglied des Landtags)",
      "coordinates": [51.057596, 13.308998]
    },
    {
      "Lfd. Nr.": 55,
      "Schule": "Gymnasium St. Augustin zu Grimma",
      "Datum TV": "06.03.2015",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Klosterstraße 1",
      "Ort": "Grimma",
      "PLZ": "04668",
      "Homepage": "www.staugustin.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "",
      "coordinates": [51.235656, 12.731214]
    },
    {
      "Lfd. Nr.": 56,
      "Schule": "Oberschule Kitzscher",
      "Datum TV": "03.04.2015",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Trageser Str. 40c",
      "Ort": "Kitzscher",
      "PLZ": "04567",
      "Homepage": "http://www.ms-kitzscher.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Ingo Hertzsch (Fußballer bei RB Leipzig)",
      "coordinates": [51.167492, 12.550575]
    },
    {
      "Lfd. Nr.": 57,
      "Schule": "Schloßbergschule",
      "Datum TV": "26.06.2015",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachsen",
      "Straße": "Schloßberg 2",
      "Ort": "Döbeln",
      "PLZ": "04720",
      "Homepage": "https://doebeln.de/index.php/bildung/foerderschulen (Haben keine eigene)",
      "Schulart": "Förderschule",
      "Patenschaft": "Stephan Siegmund (Pfarrer)",
      "coordinates": [51.121803, 13.124962]
    },
    {
      "Lfd. Nr.": 58,
      "Schule": "Romain-Rollland-Gymnasium Dresden",
      "Datum TV": "02.07.2015",
      "Region": "Dresden",
      "Landkreis": "Stadt Dresden",
      "Straße": "Weintraubenstraße 3",
      "Ort": "Dresden",
      "PLZ": "01099",
      "Homepage": "https://www.romain-rolland-gymnasium-dresden.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Christian Prunitsch (Institut für Slavistik, TU Dresden)",
      "coordinates": [51.060931, 13.754112]
    },
    {
      "Lfd. Nr.": 59,
      "Schule": "Jenaplanschule",
      "Datum TV": "09.07.2015",
      "Region": "Chemnitz",
      "Landkreis": "Erzgebirgskreis",
      "Straße": "Annabergerstr. 86",
      "Ort": "Raschau-Markersbach",
      "PLZ": "08352",
      "Homepage": "www.jenaplanschule-markersbach.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Thomas \"Kosmo\" Küchler (Musiker, Duo \"Donkong\")",
      "coordinates": [50.532529, 12.872197]
    },
    {
      "Lfd. Nr.": 60,
      "Schule": "Gymnasium Einsiedel",
      "Datum TV": "10.07.2015",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Niederwaldstraße 11",
      "Ort": "Chemnitz",
      "PLZ": "09123",
      "Homepage": "http://gy-eins.lernsax.de/info/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Michael Leutert (Politiker, Die Linke, Mitglied des Bundestags)",
      "coordinates": [50.782453, 12.973282]
    },
    {
      "Lfd. Nr.": 61,
      "Schule": "Oberschule Altendorf",
      "Datum TV": "17.07.2015",
      "Region": "Chemnitz",
      "Landkreis": "Stadt Chemnitz",
      "Straße": "Ernst-Heilmann-Straße 11",
      "Ort": "Chemnitz",
      "PLZ": "09116",
      "Homepage": "https://cms.sachsen.schule/osaltendorf/die-oberschule-altendorf/",
      "Schulart": "Oberschule",
      "Patenschaft": "Chemnitzer FC (Fußballverein)",
      "coordinates": [50.832644, 12.884320]
    },
    {
      "Lfd. Nr.": 62,
      "Schule": "2. Oberschule Kamenz",
      "Datum TV": "25.09.2015",
      "Region": "Ostsachsen",
      "Landkreis": "Landkreis Bautzen",
      "Straße": "Saarstraße 18",
      "Ort": "Kamenz",
      "PLZ": "01917",
      "Homepage": "https://cms.sachsen.schule/os2km/schule/",
      "Schulart": "Oberschule",
      "Patenschaft": "Brunhild Kurth (Politikerin, CDU, ehemalige Staatsministerin für Kultus)",
      "coordinates": [51.277286, 14.106011]
    },
    {
      "Lfd. Nr.": 63,
      "Schule": "Sächsisches Landesgymnasium St. Afra zu Meißen",
      "Datum TV": "06.10.2015",
      "Region": "Dresden",
      "Landkreis": "Landkreis Meißen",
      "Straße": "Freiheit 13",
      "Ort": "Meißen",
      "PLZ": "01662",
      "Homepage": "www.sankt-afra.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Bernd Oehler (Pfarrer, Vorsitzender Buntes Meißen)",
      "coordinates": [51.163496, 13.467976]
    },
    {
      "Lfd. Nr.": 64,
      "Schule": "Paul-Günther-Schule Geithain Ober-und Gemeinschaftsschule",
      "Datum TV": "24.03.2016",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Schillerstraße 13",
      "Ort": "Geithain",
      "PLZ": "04643",
      "Homepage": "www.paul-guenther-schule.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Petra Köpping (Politikerin, SPD, Sächsische Staatsministerin für Soziales und Gesellschaftlichen Zusammenhalt)",
      "coordinates": [51.058346, 12.691731]
    },
    {
      "Lfd. Nr.": 65,
      "Schule": "Oberschule Pegau \"Frédéric Joliot-Curie\"",
      "Datum TV": "11.04.2016",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Ernst-Reinsdorf-Straße 3",
      "Ort": "Pegau",
      "PLZ": "04523",
      "Homepage": "https://www.oberschule-pegau.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Mibrag AG",
      "coordinates": [51.168060, 12.255332]
    },
    {
      "Lfd. Nr.": 66,
      "Schule": "Gymnasium Dresden-Cotta",
      "Datum TV": "24.06.2016",
      "Region": "Dresden",
      "Landkreis": "Stadt Dresden",
      "Straße": "Cossebaudaer Str. 35",
      "Ort": "Dresden",
      "PLZ": "01157",
      "Homepage": "https://cms.sachsen.schule/gdc/start/",
      "Schulart": "Gymnasium",
      "Patenschaft": "TJG - Theater Junge Generation Dresden",
      "coordinates": [51.057961, 13.686569]
    },
    {
      "Lfd. Nr.": 67,
      "Schule": "Oberschule Groitzsch",
      "Datum TV": "17.11.2016",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Südstraße 30",
      "Ort": "Groitzsch",
      "PLZ": "04539",
      "Homepage": "www.os-groitzsch.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Oliver Fritzsche (Politiker, CDU, Mitglied des Landtags)",
      "coordinates": [51.154350, 12.283047]
    },
    {
      "Lfd. Nr.": 68,
      "Schule": "Oberschule Regis-Breitingen",
      "Datum TV": "07.02.2017",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Schulstraße 9",
      "Ort": "Regis-Breitlingen",
      "PLZ": "04565",
      "Homepage": "www.regis-breitingen.de/schule",
      "Schulart": "Oberschule",
      "Patenschaft": "Henry Graichen (Politiker, CDU, Landrat des Landkreises Leipzig)",
      "coordinates": [51.089017, 12.443089]
    },
    {
      "Lfd. Nr.": 69,
      "Schule": "Gymnasium Brandis",
      "Datum TV": "10.03.2017",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Schulstraße 3",
      "Ort": "Brandis",
      "PLZ": "04821",
      "Homepage": "www.gymnasium-brandis.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Die Prinzen (Band)",
      "coordinates": [51.331676, 12.610660]
    },
    {
      "Lfd. Nr.": 70,
      "Schule": "Petrischule Leipzig - Oberschule",
      "Datum TV": "19.06.2017",
      "Region": "Leipzig",
      "Landkreis": " Stadt Leipzig",
      "Straße": "Paul-Gruner-Str. 50",
      "Ort": "Leipzig",
      "PLZ": "04107",
      "Homepage": "www.petrischule-leipzig.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Luise Weinrich (Wissenschaftlerin)",
      "coordinates": [51.328852, 12.372410]
    },
    {
      "Lfd. Nr.": 71,
      "Schule": "Matthes-Enderlein-Gymnasium Zwötnitz",
      "Datum TV": "22.06.2017",
      "Region": "Chemnitz",
      "Landkreis": "Erzgebirgskreis",
      "Straße": "Matthes-Enderlein-Straße 2",
      "Ort": "Zwönitz",
      "PLZ": "08297",
      "Homepage": "https://gymnasium-zwoenitz.com/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Marco Wanderwitz (Politiker, CDU, Mitglied des Bundestags)",
      "coordinates": [50.633630, 12.808768]
    },
    {
      "Lfd. Nr.": 72,
      "Schule": "Gymnasium Burgstädt",
      "Datum TV": "22.06.2017",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachsen",
      "Straße": "Friedrich-Marschner-Straße 18",
      "Ort": "Burgstädt",
      "PLZ": "09217",
      "Homepage": "https://cms.sachsen.schule/gybu/start/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Bettina Junge (Redakteurin Freie Presse)",
      "coordinates": [50.915216, 12.808020]
    },
    {
      "Lfd. Nr.": 73,
      "Schule": "Gymnasium Markranstädt",
      "Datum TV": "23.06.2017",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Parkstraße 9",
      "Ort": "Markranstädt",
      "PLZ": "04420",
      "Homepage": "http://www.markranstaedt-gymnasium.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Werk 2 Leipzig (Soziokulturelles Zentrum)",
      "coordinates": [51.30494128538604, 12.220884217255692]
    },
    {
      "Lfd. Nr.": 74,
      "Schule": "Gymnasium Dresden-Klotzsche",
      "Datum TV": "23.08.2017",
      "Region": "Dresden",
      "Landkreis": "Stadt Dresden",
      "Straße": "Karl-Marx-Straße 44",
      "Ort": "Dresden",
      "PLZ": "01109",
      "Homepage": "www.gymnasium-klotzsche.de",
      "Schulart": "Gymnasium",
      "Patenschaft": "Frank Richter (ehemaliger Geschäftsführer Stiftung Frauenkirche), Banda Comunale (Band)",
      "coordinates": [51.115370, 13.772899]
    },
    {
      "Lfd. Nr.": 75,
      "Schule": "Freie Walddorfschule Leipzig",
      "Datum TV": "23.09.2017",
      "Region": "Leipzig",
      "Landkreis": "Stadt Leipzig",
      "Straße": "Berthastraße 15",
      "Ort": "Leipzig",
      "PLZ": "04357",
      "Homepage": "http://www.waldorfschule-leipzig.de",
      "Schulart": "Freie Schule",
      "Patenschaft": "Burkhard Jung (Politiker, SPD, Oberbürgermeister Stadt Leipzig), Daniela Krien (Schriftstellerin), Cheryl Shepard (Schauspielerin), Martin Petzold (Kammersänger)",
      "coordinates": [51.365027, 12.405760]
    },
    {
      "Lfd. Nr.": 76,
      "Schule": "Gymnasium Markneukirchen",
      "Datum TV": "17.10.2017",
      "Region": "Westsachsen",
      "Landkreis": "Vogtlandkreis",
      "Straße": "Schulstraße 3",
      "Ort": "Markneukirchen",
      "PLZ": "08258",
      "Homepage": "https://www.gymnasiummarkneukirchen.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "TBVLemgo (Fußballverein), Yvonne Magwas (Politikerin, CDU, Mitglied des Bundestags)",
      "coordinates": [50.312690, 12.333492]
    },
    {
      "Lfd. Nr.": 77,
      "Schule": "Gymnasium \"Am breiten Teich\" Borna",
      "Datum TV": "04.12.2017",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Am Breiten Teich 4",
      "Ort": "Borna",
      "PLZ": "04552",
      "Homepage": "https://www.gymnasium-borna.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Diana Schell (Radio- und Fernsehmoderatorin)",
      "coordinates": [51.128903, 12.496309]
    },
    {
      "Lfd. Nr.": 78,
      "Schule": "TRIAS Oberschule Elsterberg",
      "Datum TV": "20.12.2017",
      "Region": "Westsachsen",
      "Landkreis": "Vogtlandkreis",
      "Straße": "Wallstraße 16",
      "Ort": "Elsterberg",
      "PLZ": "07985",
      "Homepage": "http://www.trias-oberschule-elsterberg.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Eric Holtschke (Politiker, SPD, Mitglied Stadtrat Plauen)",
      "coordinates": [50.608125, 12.167201]
    },
    {
      "Lfd. Nr.": 79,
      "Schule": "Anton-Philipp-Reclam-Schule Leipzig",
      "Datum TV": "16.03.2018",
      "Region": "Leipzig",
      "Landkreis": "Stadt Leipzig",
      "Straße": "Tarostraße 4",
      "Ort": "Leipzig",
      "PLZ": "04103",
      "Homepage": "https://www.reclamgymnasium.de/de/home/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Galerie für zeitgenössische Kunst Leipzig",
      "coordinates": [51.325496, 12.385456]
    },
    {
      "Lfd. Nr.": 80,
      "Schule": "Labor Schule Omse e.V.",
      "Datum TV": "25.05.2018",
      "Region": "Dresden",
      "Landkreis": "Stadt Dresden",
      "Straße": "Espenstraße 3",
      "Ort": "Dresden",
      "PLZ": "01169",
      "Homepage": "www.omse-ev.de",
      "Schulart": "Freie Schule",
      "Patenschaft": "Rugby Löwen Leipzig (Rugbyverein)",
      "coordinates": [51.044129, 13.663929]
    },
    {
      "Lfd. Nr.": 81,
      "Schule": "Christliches Gymnasium Rudolph Stempel ",
      "Datum TV": "14.06.2018",
      "Region": "Dresden",
      "Landkreis": "Landkreis Meißen",
      "Straße": "Lange Straße 51",
      "Ort": "Riesa",
      "PLZ": "01587",
      "Homepage": "www.schulzentrum-riesa.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Zärtlichkeit mit Freunden (Theatergruppe)",
      "coordinates": [51.303897, 13.269406]
    },
    {
      "Lfd. Nr.": 82,
      "Schule": "Johannes-Kepler-Gymnasium",
      "Datum TV": "20.06.2018",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachen",
      "Straße": "Humboldtplatz 1",
      "Ort": "Chemnitz",
      "PLZ": "09130",
      "Homepage": "https://www.kepler-chemnitz.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Chemnitzer FC (Fußballverein)",
      "coordinates": [50.835188, 12.948010]
    },
    {
      "Lfd. Nr.": 83,
      "Schule": "Magnus-Gottfried-Lichtwer Gymnasium Wurzen",
      "Datum TV": "26.06.2018",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Am Gymnasium 3",
      "Ort": "Wurzen",
      "PLZ": "04808",
      "Homepage": "https://gymnasium-wurzen-de.prossl.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Martin Hamann (Skispringer)",
      "coordinates": [51.375647, 12.752099]
    },
    {
      "Lfd. Nr.": 84,
      "Schule": "Oberschule Brandis ",
      "Datum TV": "28.06.2018",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Poststraße 20",
      "Ort": "Brandis",
      "PLZ": "04821",
      "Homepage": "http://oberschule.ms-brandis.de/",
      "Schulart": "Oberschule",
      "Patenschaft": "Simon Yakoub (Judoka)",
      "coordinates": [51.331242, 12.612542]
    },
    {
      "Lfd. Nr.": 85,
      "Schule": "128. Oberschule \"Carla von Wasa\" Dresden",
      "Datum TV": "28.06.2018",
      "Region": "Dresden",
      "Landkreis": "Stadt Dresden",
      "Straße": "Rudolf-Bergander-Ring 3",
      "Ort": "Dresden",
      "PLZ": "01219",
      "Homepage": "os128-carola-von-wasa.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Dresden Monarchs (Football-Team)",
      "coordinates": [51.021440, 13.778761]
    },
    {
      "Lfd. Nr.": 86,
      "Schule": "Pufendorfgymnasium",
      "Datum TV": "29.06.2018",
      "Region": "Chemnitz",
      "Landkreis": "Mittelsachen",
      "Straße": "Turnerstraße 16",
      "Ort": "Flöha",
      "PLZ": "09557",
      "Homepage": "https://www.gymnasium-floeha.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Uwe Dziuballa (Betreiber Jüdisches Restaurant \"Schalom\" Chemnitz)",
      "coordinates": [50.860847, 13.061081]
    },
    {
      "Lfd. Nr.": 87,
      "Schule": "DFPA-Regenbogen-Gymnasium Zwenkau",
      "Datum TV": "13.08.2018",
      "Region": "Leipzig",
      "Landkreis": "Landkreis Leipzig",
      "Straße": "Pestalozzistraße 15",
      "Ort": "Zwenkau",
      "PLZ": "04442",
      "Homepage": "https://www.dpfa-zwenkau.de/",
      "Schulart": "Gymnasium",
      "Patenschaft": "Maximilian Klas (Schauspieler)",
      "coordinates": [51.218477, 12.329829]
    },
    {
      "Lfd. Nr.": 88,
      "Schule": "Marie-Curie-Oberschule Dohna",
      "Datum TV": "08.05.2019",
      "Region": "Dresden",
      "Landkreis": "Landkreis Sächsische Schweiz - Osterzgebirge",
      "Straße": "Burgstraße 15",
      "Ort": "Dohna",
      "PLZ": "01809",
      "Homepage": "www.os-dohna.de",
      "Schulart": "Oberschule",
      "Patenschaft": "Sebastian Reißig (Geschäftsführer Aktion Zivilcourage e.V.)",
      "coordinates": [50.956507, 13.860186]
    },
    {
      "Lfd. Nr.": 89,
      "Schule": "Louise-Otto-Peters-Schule Gymnasium der Stadt Leipzig",
      "Datum TV": "21.06.2019",
      "Region": "Leipzig",
      "Landkreis": "Stadt Leipzig",
      "Straße": "Bornaische Straße 104",
      "Ort": "Leipzig",
      "PLZ": "04277",
      "Homepage": "https://cms.sachsen.schule/gymlops",
      "Schulart": "Gymnasium",
      "Patenschaft": "KAFVKA (Band)",
      "coordinates": [51.301839, 12.383104]
    },
    {
      "Lfd. Nr.": 90,
      "Schule": "Donner + Kern gGmbH Fach- und Berufsfachschule für Sozialwesen",
      "Datum TV": "04.07.2019",
      "Region": "Dresden",
      "Landkreis": "Stadt Dresden",
      "Straße": "Berliner Straße 11",
      "Ort": "Dresden",
      "PLZ": "01067",
      "Homepage": "www.donner-kern.schule",
      "Schulart": "Berufsschule",
      "Patenschaft": "Outlaw Dresden gGmbH\nKita Kleiner Globus",
      "coordinates": [51.053989, 13.717270]
    },
    {
      "Lfd. Nr.": 91,
      "Schule": "Freie berufsbildende Schule Dresden",
      "Datum TV": "23.11.2020",
      "Region": "Dresden",
      "Landkreis": "Stadt Dresden",
      "Straße": "Espenstraße 5",
      "Ort": "Dresden",
      "PLZ": "01069",
      "Homepage": "https://www.fbs-dresden.de/",
      "Schulart": "Berufsschule",
      "Patenschaft": "Claus-Peter Reisch (ehemaliger Mission-Lifeline-Kapitän)",
      "coordinates": [51.044763470281765, 13.66357999831565]
    },
    {
      "Lfd. Nr.": 92,
      "Schule": "Landesschule für Blinde und Sehbehinderte",
      "Schulart": "Förderschule",
      "Straße": "Flemmingstraße 8",
      "Ort": "Chemnitz",
      "PLZ": "09116",
      "Landkreis": "Stadt Chemnitz",
      "Region": "Chemnitz",
      "Datum TV": "30.06.21",
      "Patenschaft": "Beyond the Black (Band), Chemnitzer Ballspielclub / Goalball",
      "Homepage": "https://www.lbs-chemnitz.sachsen.de/",
      "coordinates": [
        50.84278,
        12.87813
      ]
    },
    {
      "Lfd. Nr.": 93,
      "Schule": "DPFA-Regenbogen-Grundschule Göritz",
      "Schulart": "Grundschule",
      "Straße": "Friedrich-Engels-Straße 42",
      "Ort": "Görlitz",
      "PLZ": "02827",
      "Landkreis": "Landkreis Görlitz",
      "Region": "Ostsachsen",
      "Datum TV": "02.07.21",
      "Patenschaft": "Franziska Schubert (Politikerin, DIE GRÜNEN, Landtagsabgeordnete), Rolf Zuckowski (Liedermacher)",
      "Homepage": "https://www.dpfa-goerlitz.de/",
      "coordinates": [
        51.12828,
        14.97125
      ]
    },
    {
      "Lfd. Nr.": 94,
      "Schule": "Paul-Robeson-Schule",
      "Schulart": "Oberschule",
      "Straße": "Jungmannstraße 5",
      "Ort": "Leipzig",
      "PLZ": "04159",
      "Landkreis": "Stadt Leipzig",
      "Region": "Leipzig",
      "Datum TV": "16.07.21",
      "Patenschaft": "Sayes (Leipziger Rapper)",
      "Homepage": "https://paul-robeson-schule.de/",
      "coordinates": [
        51.38293,
        12.32234
      ]
    },
    {
      "Lfd. Nr.": 95,
      "Schule": "76. Oberschule",
      "Schulart": "Oberschule",
      "Straße": "Merbitzer Str. 9",
      "Ort": "Dresden",
      "PLZ": "01157",
      "Landkreis": "Landeshauptstadt Dresden",
      "Region": "Dresden",
      "Datum TV": "19.07.21",
      "Patenschaft": "Schauburg Dresden (Kino)",
      "Homepage": "http://www.76-oberschule-dresden.de/",
      "coordinates": [
        51.06736,
        13.67087
      ]
    },
    {
      "Lfd. Nr.": 96,
      "Schule": "Friedrich-Arnold-Brockhaus-Schule",
      "Schulart": "Gymnasium",
      "Straße": "Kieler Straße 72B",
      "Ort": "Leipzig",
      "PLZ": "04357",
      "Landkreis": "Stadt Leipzig",
      "Region": "Leipzig",
      "Datum TV": "20.07.21",
      "Patenschaft": "Peter Schneider (Schauspieler)",
      "Homepage": "https://cms.sachsen.schule/gymlbrock/startseite/",
      "coordinates": [
        51.37607,
        12.41619
      ]
    },
    {
      "Lfd. Nr.": 97,
      "Schule": "Carl-Friedrich-Goerdeler-Oberschule",
      "Schulart": "Oberschule",
      "Straße": "Paul-Gruner-Straße 59",
      "Ort": "Leipzig",
      "PLZ": "04107",
      "Landkreis": "Stadt Leipzig",
      "Region": "Leipzig",
      "Datum TV": "06.09.21",
      "Patenschaft": "Frank Elstermann (Geschäftsführer der naTo Leipzig)",
      "Homepage": "https://www.semper-schulen.de/allgemeinbildende-schulen/carl-friedrich-goerdeler-oberschule-leipzig/schulkonzept/",
      "coordinates": [
        51.32890456278481, 
        12.370413000929412
      ]
    },
    {
      "Lfd. Nr.": 98,
      "Schule": "Rudolf-Hildebrand-Schule",
      "Schulart": "Gymnasium",
      "Straße": "Mehringstraße 8",
      "Ort": "Markkleeberg",
      "PLZ": "04416",
      "Landkreis": "Landkreis Leipzig",
      "Region": "Leipzig",
      "Datum TV": "07.10.21",
      "Patenschaft": "Banda Comunale (Band)",
      "Homepage": "http://www.rudolf-hildebrand-schule.de/",
      "coordinates": [
        51.27854506582302, 
        12.353007806588245
      ]
    },
    {
      "Lfd. Nr.": 99,
      "Schule": "Städtisches Gymnasium Riesa",
      "Schulart": "Gymnasium",
      "Straße": "Joseph-Haydn-Straße 4",
      "Ort": "Riesa",
      "PLZ": "01589",
      "Landkreis": "Landkreis Meißen",
      "Region": "Dresden",
      "Datum TV": "08.10.21",
      "Homepage": "https://www.sgriesa.de/",
      "Patenschaft": "Marko Müller (Oberbürgermeister der Stadt Riesa) und Kerstin Köhler (Bürgermeisterin für Finanzen, Bildung, Service und Ordnung)",
      "coordinates": [
        51.30192886808724, 
        13.300602097953318
      ]
    },
    {
      "Lfd. Nr.": 100,
      "Schule": "Ehrenfried-Walther-von-Tschirnhaus-Gymnasium",
      "Schulart": "Gymnasium",
      "Straße": "Bernhardstraße 18",
      "Ort": "Dresden",
      "PLZ": "01069",
      "Landkreis": "Stadt Dresden",
      "Region": "Dresden",
      "Datum TV": "03.11.21",
      "Patenschaft": "Banda Comunale (Band), Sebastian Reißig (Geschäftsführer der Aktion Zivilcourage e.V.)",
      "Homepage": "https://ewvt.de/",
      "coordinates": [
        51.03692564338473, 
        13.723966034640927
      ]
    },
    {
      "Lfd. Nr.": 101,
      "Schule": "Pestalozzi-Oberschule Hartha",
      "Schulart": "Oberschule",
      "Straße": "Pestalozzistraße 27",
      "Ort": "Hartha",
      "PLZ": "04746",
      "Landkreis": "Landkreis Mittelsachsen",
      "Region": "Chemnitz",
      "Datum TV": "08.04.22",
      "Patenschaft": "Hendrik Duryn (Schauspieler)",
      "Homepage": "http://www.pestalozzi-schule-hartha.de",
      "coordinates": [
        51.0957372007994, 
        12.974507812425626
      ]
    },
    {
      "Lfd. Nr.": 102,
      "Schule": "Humboldtschule Zwickau",
      "Schulart": "Oberschule",
      "Straße": "Lothar-Streit-Straße 2",
      "Ort": "Zwickau",
      "PLZ": "08056",
      "Landkreis": "Landkreis Zwickau",
      "Region": "Westsachsen",
      "Datum TV": "04.05.22",
      "Patenschaft": "Pia Findeiß (ehemalige Oberbürgermeisterin der Stadt Zwickau)",
      "Homepage": "https://www.humboldtschule-zwickau.de",
      "coordinates": [
        50.71397677941207, 
        12.495224873799836
      ]
    },
    {
      "Lfd. Nr.": 103,
      "Schule": "62. Oberschule \"Friedrich Schiller\"",
      "Schulart": "Oberschule",
      "Straße": "Fidelio-F.-Finke-Str. 15",
      "Ort": "Dresden",
      "PLZ": "01326",
      "Landkreis": "Stadt Dresden",
      "Region": "Dresden",
      "Datum TV": "29.06.22",
      "Patenschaft": "Banda Comunale (Band), Dirk Hilbert (Oberbürgermeister der Stadt Dresden)",
      "Homepage": "https://cms.sachsen.schule/os62dd/start/",
      "coordinates": [
        51.05206448955716, 
        13.816192624059756
      ]
    },
    {
      "Lfd. Nr.": 104,
      "Schule": "Adolph-Kolping-Schule",
      "Schulart": "Berufsschule",
      "Straße": "Kasernenstraße 59",
      "Ort": "Plauen",
      "PLZ": "08523",
      "Landkreis": "Vogtlandkreis",
      "Region": "Westsachsen",
      "Datum TV": "12.07.22",
      "Patenschaft": "Andrea Sachs (Kommunale Integrationskoordinatorin)",
      "Homepage": "https://www.aksplauen.de/",
      "coordinates": [
        50.489890846386146, 
        12.106196173186248
      ]
    },
    {
      "Lfd. Nr.": 105,
      "Schule": "DPFA-Regenbogen-Gymnasium Augustusburg",
      "Schulart": "Gymnasium",
      "Straße": "Marienberger Straße 29a",
      "Ort": "Augustusburg",
      "PLZ": "09573",
      "Landkreis": "Landkreis Mittelsachsen",
      "Region": "Chemnitz",
      "Datum TV": "13.07.22",
      "Patenschaft": "Dirk Neubauer (Landrat Landkreis Mittelsachsen, ehemaliger Bürgermeister der Stadt Augustusburg)",
      "Homepage": "https://www.dpfa-augustusburg.de/",
      "coordinates": [
        50.8089456220139, 
        13.101382871440425
      ]
    },
    {
      "Lfd. Nr.": 106,
      "Schule": "WIR Freie Oberschule Bernsdorf",
      "Schulart": "Oberschule",
      "Straße": "Alte Schulstraße 3",
      "Ort": "Bernsdorf",
      "PLZ": "02994",
      "Landkreis": "Landkreis Bautzen",
      "Region": "Ostsachsen",
      "Datum TV": "30.08.22",
      "Patenschaft": "Simon von MDR Sputnik | Krom (Moderator und Rapper)",
      "Homepage": "https://www.oberschule-bernsdorf.de/",
      "coordinates": [51.370009620831624, 14.063449157989046]
    },
    {
      "Lfd. Nr.": 107,
      "Schule": "Gymnasium Tolkewitz Dresden",
      "Schulart": "Gymnasium",
      "Straße": "Wehlener Straße 38",
      "Ort": "Dresden",
      "PLZ": "01279",
      "Landkreis": "Stadt Dresden",
      "Region": "Dresden",
      "Datum TV": "31.08.22",
      "Patenschaft": "Aehm Ahmad (Pianist)",
      "Homepage": "https://www.gymnasium-tolkewitz.de/",
      "coordinates": [51.035190976692505, 13.81629147846341]
    },
    {
      "Lfd. Nr.": 108,
      "Schule": "Schule am Palmengarten",
      "Schulart": "Gymnasium",
      "Straße": "Karl-Heine-Straße 22B",
      "Ort": "Leipzig",
      "PLZ": "04229",
      "Landkreis": "Stadt Leipzig",
      "Region": "Leipzig",
      "Datum TV": "05.10.22",
      "Patenschaft": "Sandra Hüller (Schauspielerin)",
      "Homepage": "https://schule-am-palmengarten.de/",
      "coordinates": [51.33344054401905, 12.342466509700497]
    },
    {
      "Lfd. Nr.": 109,
      "Schule": "Lessing-Gymnasium Hohenstein-Ernstthal",
      "Schulart": "Gymnasium",
      "Straße": "Schulstraße 9",
      "Ort": "Hohenstein-Ernstthal",
      "PLZ": "09337",
      "Landkreis": "Landkreis Zwickau",
      "Region": "Westsachsen",
      "Datum TV": "14.12.22",
      "Patenschaft": "Oliver Nießlein (Human Aid Collective e.V.)",
      "Homepage": "https://www.lghe.org/",
      "coordinates": [50.80157659295788, 12.711032023934198]
    },
    {
      "Lfd. Nr.": 110,
      "Schule": "120. Schule - Grundschule der Stadt Leipzig",
      "Schulart": "Grundschule",
      "Straße": "Martin-Herrmann-Straße 1",
      "Ort": "Leipzig",
      "PLZ": "04249",
      "Landkreis": "Stadt Leipzig",
      "Region": "Leipzig",
      "Datum TV": "07.03.23",
      "Patenschaft": "Christian Lissek (KMV Sachsen e. V. Körper- und Mehrfachbehinderten Verband)",
      "Homepage": "https://www.grundschule-grosszschocher.de/",
      "coordinates": [51.30768283932297, 12.32238969075638]
    },
    {
      "Lfd. Nr.": 111,
      "Schule": "Gymnasium Dresden-Johannstadt",
      "Schulart": "Gymnasium",
      "Straße": "Pfotenhauerstraße 42",
      "Ort": "Dresden",
      "PLZ": "01307",
      "Landkreis": "Stadt Dresden",
      "Region": "Dresden",
      "Datum TV": "24.05.23",
      "Patenschaft": "Nazanin Zandi (freischaffende Künstlerin & Illustratorin aus Dresden)",
      "Homepage": "https://www.gymnasium-dresden-johannstadt.de/",
      "coordinates": [51.05608712600152, 13.767476612040639]
    },
    {
      "Lfd. Nr.": 112,
      "Schule": "Wilhelm-Hauff-Grundschule Leipzig",
      "Schulart": "Grundschule",
      "Straße": "Diderotstraße 37",
      "Ort": "Leipzig",
      "PLZ": "04159",
      "Landkreis": "Stadt Leipzig",
      "Region": "Leipzig",
      "Datum TV": "01.06.23",
      "Patenschaft": "Henry Lewkowitz, Geschäftsführer Erich-Zeigner-Haus e.V.",
      "Homepage": "https://cms.sachsen.schule/hauffgs/startseite/",
      "coordinates": [51.372044047708215, 12.343551596943497]
    },
    {
      "Lfd. Nr.": 113,
      "Schule": "Max-Klinger-Gymnasium Leipzig",
      "Schulart": "Gymnasium",
      "Straße": "Miltitzer Weg 4",
      "Ort": "Leipzig",
      "PLZ": "04205",
      "Landkreis": "Stadt Leipzig",
      "Region": "Leipzig",
      "Datum TV": "20.06.23",
      "Patenschaft": "Heizhaus Leipzig / urban souls e.V.",
      "Homepage": "http://max-klinger-schule.de/",
      "coordinates": [51.322745393729114, 12.265102263180951]
    },
    {
      "Lfd. Nr.": 114,
      "Schule": "Evangelisches Kreuzgymnasium Dresden",
      "Schulart": "Gymnasium",
      "Straße": "Dornblüthstraße 4",
      "Ort": "Dresden",
      "PLZ": "01277",
      "Landkreis": "Stadt Dresden",
      "Region": "Dresden",
      "Datum TV": "26.06.23",
      "Patenschaft": "Martin Dulig (Politiker, SPD, Staatsminister für Wirtschaft, Arbeit und Verkehr)",
      "Homepage": "https://kreuzgymnasium.de/",
      "coordinates": [51.04671361633072, 13.801718363459846]
    },
    {
      "Lfd. Nr.": 115,
      "Schule": "145. Oberschule Dresden",
      "Schulart": "Oberschule",
      "Straße": "Gehestraße 2",
      "Ort": "Dresden",
      "PLZ": "01127",
      "Landkreis": "Stadt Dresden",
      "Region": "Dresden",
      "Datum TV": "29.06.23",
      "Patenschaft": "Staatschauspielhaus Dresden",
      "Homepage": "https://145-oberschule-dresden.de/",
      "coordinates": [51.073098140162635, 13.73146386377092]
    },
    {
      "Lfd. Nr.": 116,
      "Schule": "Gymnasium Dresden-Pieschen",
      "Schulart": "Gymnasium",
      "Straße": "Erfurter Str. 17",
      "Ort": "Dresden",
      "PLZ": "01127",
      "Landkreis": "Stadt Dresden",
      "Region": "Dresden",
      "Datum TV": "29.06.23",
      "Patenschaft": "Staatschauspielhaus Dresden; Objektiv e.V.",
      "Homepage": "https://gymnasium-pieschen.de/",
      "coordinates": [51.07224846490588, 13.732677735873533]
    },
    {
      "Lfd. Nr.": 117,
      "Schule": "DPFA-Regenbogen-Schulen Chemnitz",
      "Schulart": "Gymnasium, Oberschule",
      "Straße": "Emilienstraße 48",
      "Ort": "Chemnitz",
      "PLZ": "09131",
      "Landkreis": "Stadt Chemnitz",
      "Region": "Chemnitz",
      "Datum TV": "06.07.23",
      "Patenschaft": "Julia Voigt (Hand in Hand e.V., Weltecho Chemitz)",
      "Homepage": "https://www.dpfa-chemnitz.de/",
      "coordinates": [50.85167636898493, 12.938795576211346]
    },
    {
      "Lfd. Nr.": 118,
      "Schule": "Abendgymnasium Chemnitz",
      "Schulart": "Gymnasium",
      "Straße": "Arno-Schreiter-Straße 3",
      "Ort": "Chemnitz",
      "PLZ": "09123",
      "Landkreis": "Stadt Chemnitz",
      "Region": "Chemnitz",
      "Datum TV": "06.07.23",
      "Patenschaft": "Torsten Loibl (Basketballtrainer)",
      "Homepage": "http://www.abendgymnasium-chemnitz.de/",
      "coordinates": [50.79178269290294, 12.903711943106458]
    },
    {
      "Lfd. Nr.": 119,
      "Schule": "Werner-von-Siemens-Gymnasium Großenhain",
      "Schulart": "Gymnasium",
      "Straße": "Franz-Schubert-Allee 29",
      "Ort": "Großenhain",
      "PLZ": "01558",
      "Landkreis": "Landkreis Meißen",
      "Region": "Dresden",
      "Datum TV": "21.08.23",
      "Patenschaft": "Alberttreff e.V. (Soziokulturelles Zentrum), Lucas Krzikalla (Handballer)",
      "Homepage": "https://www.gymnasium-grossenhain.de/",
      "coordinates": [51.29430822818411, 13.53327801526706]
    },
    {
      "Lfd. Nr.": 120,
      "Schule": "Evangelisches Gymnasium \"Lernwelten\" Böhlen",
      "Schulart": "Gymnasium",
      "Straße": "Schulstraße 6",
      "Ort": "Böhlen OT Großdeuben",
      "PLZ": "04564",
      "Landkreis": "Landkreis Leipzig",
      "Region": "Leipzig",
      "Datum TV": "12.09.23",
      "Patenschaft": "Johannes Herweg (Schriftsteller)",
      "Homepage": "https://www.lernwelten-schule.de/",
      "coordinates": [51.23793977233192, 12.38329580334905]
    },
    {
      "Lfd. Nr.": 121,
      "Schule": "Werner-Heisenberg-Schule",
      "Schulart": "Gymnasium",
      "Straße": "Renftstraße 3",
      "Ort": "Leipzig",
      "PLZ": "04159",
      "Landkreis": "Stadt Leipzig",
      "Region": "Leipzig",
      "Datum TV": "13.09.23",
      "Patenschaft": "The Firebirds (Band)",
      "Homepage": "https://www.whs-leipzig.de/",
      "coordinates": [51.367476989542425, 12.342429388766528]
    },
    {
      "Lfd. Nr.": 122,
      "Schule": "Schule am Addis-Abeba-Platz",
      "Schulart": "Grundschule",
      "Straße": "Addis-Abeba-Platz 1",
      "Ort": "Leipzig",
      "PLZ": "04103",
      "Landkreis": "Stadt Leipzig",
      "Region": "Leipzig",
      "Datum TV": "22.09.23",
      "Patenschaft": "Palwascha Azmarai",
      "Homepage": "https://schule-am-addis-abeba-platz.de/",
      "coordinates": [51.333883074405435, 12.379676537430662]
    }
]

export { SCHOOLS, COORDINATIONS };